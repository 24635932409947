<template>
  <div class="locationseditor">
    <c-loader v-if="isResetting"/>

    <template v-else>
      <div v-if="$slots['location-description']">
        <slot name="location-description"/>
        <hr/>
      </div>

      <formular-locations
        :initialValues="[].concat(this.initialValues.userLocations || [], this.initialValues.locationsWithAddresses || [])"
        :translations="TRANSLATIONS"
        @formular:addlocationform-open="showFormActions = false"
        @formular:addlocationform-close="showFormActions = true"
        @formular:init="$value => setFormData($value.formProperty, $value, $value.modify)"
        @formular:change="$value => setFormData($value.formProperty, $value, $value.modify)"
      />
    </template>

    <template v-if="showFormActions">
      <hr>

      <b-form-row class="locationseditor-actions justify-content-between align-items-center">
        <b-col cols="auto">
          <b-button variant="secondary" size="sm" @click="resetData">{{ $T('formAbortButton') }}</b-button>
        </b-col>

        <b-col cols="auto">
          <b-button variant="primary" :disabled="!isValidFormData" @click="onSubmit">{{ $T('formSaveButton') }}</b-button>
        </b-col>
      </b-form-row>
    </template>
  </div>
</template>

<script>
import { LOCATIONSEARCH_FORMDATA_DEFAULTS, LOCATIONSSEARCH_PROPERTY_OPTIONS } from '@/assets/js/config/server'
import { LOCATIONSEARCH_FORM_OPTIONS } from '@/assets/js/config/client'

import { copy } from '@/assets/js/helper/object'

import FormularLocations from '@/components/registration/FormularLocations'

const ID_PROPERTIES = LOCATIONSSEARCH_PROPERTY_OPTIONS.idProperties
const ENTITY_ID_KEYS = ['id'].concat(Object.values(ID_PROPERTIES))

export default {
  name: 'Locations.Edit',
  components: {
    FormularLocations
  },
  data () {
    return {
      showFormActions: true,
      isResetting: false,
      metaData: copy(LOCATIONSEARCH_FORMDATA_DEFAULTS),
      formData: copy(LOCATIONSEARCH_FORMDATA_DEFAULTS)
    }
  },
  computed: {
    userLocations () {
      return [].concat(this.$store.getters['profile/getLocations'].userLocations || [], this.$store.getters['profile/getLocations'].locationRequests || [])
        .map(l => ({
          [ID_PROPERTIES.existing]: l[ID_PROPERTIES.existing],
          isAccepted: l.isAccepted
        }))
    },
    initialValues () {
      return Object.keys(this.formData)
        .reduce((data, dKey) => Object.assign(data, { [dKey]: Object.values(this.formData[dKey] || {}).length > 0 ? this.formData[dKey] : this.metaData[dKey] }), {})
    },
    isValidFormData () {
      return LOCATIONSEARCH_FORM_OPTIONS.validation(this.formData, this.metaData)
    }
  },
  methods: {
    setInitialLocations () {
      this.metaData.userLocations = this.userLocations
    },
    setFormData (formProperty = '', formData = {}, modify = null) {
      const DATA_DEFAULT = JSON.parse(JSON.stringify(LOCATIONSEARCH_FORMDATA_DEFAULTS[formProperty]))

      if (formData.isValid) {
        if (Array.isArray(DATA_DEFAULT)) {
          const ID_PROPERTY_REGEX = new RegExp(`^(${ENTITY_ID_KEYS.join('|')})$`)
          const ID_KEY = Object.keys(formData.controls).find(cKey => ID_PROPERTY_REGEX.test(cKey))

          if (modify === true) {
            const existingIndex = this.formData[formProperty].findIndex(d => d[ID_KEY] === formData.controls[ID_KEY])

            this.$set(this.formData[formProperty], existingIndex >= 0 ? existingIndex : this.formData[formProperty].length, formData.controls)
          } else if (modify === false) {
            this.$set(this.formData, formProperty, this.formData[formProperty].filter(d => d[ID_KEY] !== formData.controls[ID_KEY]))
          } else {
            this.$set(this.formData, formProperty, DATA_DEFAULT.concat(formData.controls))
          }
        } else {
          this.$set(this.formData, formProperty, formData.controls)
        }
      } else {
        this.$set(this.formData, formProperty, DATA_DEFAULT)
      }
    },
    resetData () {
      this.isResetting = true

      this.metaData = copy(LOCATIONSEARCH_FORMDATA_DEFAULTS)
      this.formData = copy(LOCATIONSEARCH_FORMDATA_DEFAULTS)
      this.setInitialLocations()

      this.$nextTick(() => {
        this.isResetting = false
      })
    },
    onSubmit () {
      if (this.isValidFormData) {
        this.$store.dispatch('userlocations/edit', this.formData)
          .then(() => {
            window.location.reload()
          })
      }
    }
  },
  created () {
    this.setInitialLocations()
  }
}
</script>

<style lang="scss">
.locationseditor {}
</style>
